<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy">
    <ul class="el-dropdown-menu el-popper dropdown_box" :class="[size && `el-dropdown-menu--${size}`]" v-show="showPopper">
      <slot></slot>
    </ul>
  </transition>
</template>
<script>
  import Popper from './vue-poper';

  export default {
    name: 'ElDropdownMenu',

    componentName: 'ElDropdownMenu',

    mixins: [Popper],

    props: {
      visibleArrow: {
        type: Boolean,
        default: true
      },
      arrowOffset: {
        type: Number,
        default: 0
      }
    },

    data() {
      return {
        size: this.dropdown.dropdownSize
      };
    },

    inject: ['dropdown'],

    created() {
      this.$on('updatePopper', this.updatePopperCallback);
      this.$on('visible', this.visibleCallback);
    },
    methods:{
      updatePopperCallback(){
        if (this.showPopper) this.updatePopper();
      },
      visibleCallback(val){
        this.showPopper = val;
      }
    },
    beforeDestroy() {
      this.$off('updatePopper', this.updatePopperCallback);
      this.$off('visible', this.visibleCallback);
    },
    mounted() {
      this.dropdown.popperElm = this.popperElm = this.$el;
      this.referenceElm = this.dropdown.$el;
      // compatible with 2.6 new v-slot syntax
      // issue link https://github.com/ElemeFE/element/issues/14345
      this.dropdown.initDomOperation();
    },

    watch: {
      'dropdown.placement': {
        immediate: true,
        handler(val) {
          this.currentPlacement = val;
        }
      }
    }
  };
</script>


<style lang="scss" scoped>
.dropdown_box{
  z-index: 999999!important;
}
</style>